import React, { useState } from 'react';
import axios from 'axios';
import './PublishCardPage.css';
import CustomDialog from './CustomDialog'; // 引入自定义弹出框
import { CKEditor } from '@ckeditor/ckeditor5-react'; // 引入 CKEditor
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'; // 引入 Classic 编辑器构建

const CardPublishPage = () => {
  const [cardData, setCardData] = useState({
    title: '',
    content: '',
    label: '',
    country: '',
    additionalInfo: '',
  });
  const [image, setImage] = useState('');
  const [error, setError] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false); // 控制弹出框的状态
  const [dialogMessage, setDialogMessage] = useState(''); // 弹出框显示的消息

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCardData({ ...cardData, [name]: value });
  };

  const handleContentChange = (event, editor) => {
    const data = editor.getData();
    setCardData({ ...cardData, content: data });
  };

  const handleImageChange = (e) => {
    setImage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const token = localStorage.getItem('token'); // 获取 token

    const payload = {
      image: image || '',
      title: cardData.title,
      content: cardData.content,
      label: cardData.label,
      country: cardData.country,
      additionalInfo: cardData.additionalInfo || '无',
    };

    try {
      const response = await axios.post('http://romi.one:8080/ireland-social/api/cards/publish', payload, {
        headers: {
          Authorization: token, // 添加 token 到请求头
        },
      });

      if (response.status === 201) {
        // 设置弹出框的消息内容
        setDialogMessage('Card published successfully!');
        // 显示弹出框
        setIsDialogOpen(true);
        // 清空表单内容
        setCardData({
            title: '',
            content: '',
            label: '',
            country: '',
            additionalInfo: '',
        });
        setImage(''); // 清空图片 URL
        }
    } catch (err) {
      setError('Failed to publish the card. Please try again.');
    }
  };

  const closeDialog = () => {
    // 清空表单内容
    setCardData({
      title: '',
      content: '',
      label: '',
      country: '',
      additionalInfo: '',
    });
    setImage(''); // 清空图片 URL
    setIsDialogOpen(false); // 关闭弹出框
  };

  return (
    <div className="card-publish-container">
      <h1 className="title">Publish a Card</h1>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit} className="card-form">
        <div className="form-group">
          <label>Title:</label>
          <input
            type="text"
            name="title"
            value={cardData.title}
            onChange={handleChange}
            required
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label>Content:</label>
          <CKEditor
            editor={ClassicEditor}
            data={cardData.content}
            onChange={handleContentChange}
            className="rich-text-editor"
          />
        </div>
        <div className="form-group">
          <label>Label:</label>
          <input
            type="text"
            name="label"
            value={cardData.label}
            onChange={handleChange}
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label>Country:</label>
          <input
            type="text"
            name="country"
            value={cardData.country}
            onChange={handleChange}
            required
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label>Additional Info:</label>
          <textarea
            name="additionalInfo"
            value={cardData.additionalInfo}
            onChange={handleChange}
            className="input-field textarea"
          />
        </div>
        <div className="form-group">
          <label>Image URL:</label>
          <input
            type="text"
            name="image"
            value={image}
            onChange={handleImageChange}
            className="input-field"
          />
        </div>
        <div className="form-group">
          <button type="submit" className="submit-button">Publish Card</button>
        </div>
      </form>

      {/* 自定义弹出框 */}
      <CustomDialog 
        isOpen={isDialogOpen} 
        onClose={closeDialog} 
        title="Success" 
        message={dialogMessage} 
      />
    </div>
  );
};

export default CardPublishPage;
